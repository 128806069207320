import type { RouteLocationNormalized } from 'vue-router';
import { AnalyticsBrowser } from '@segment/analytics-next';
import config from '@/config/widgets';
import type AnalyticsModule from './AnalyticsModule';

export default class implements AnalyticsModule {
  analytics: AnalyticsBrowser;

  constructor() {
    this.analytics = AnalyticsBrowser.load(
      { writeKey: config.segmentWriteKey, cdnURL: 'https://events-cdn.riskledger.com' },
      {
        integrations: {
          'Segment.io': {
            apiHost: 'events-api.riskledger.com/v1',
          },
        },
      },
    );
  }

  trackView(to: RouteLocationNormalized, from: RouteLocationNormalized) {
    this.analytics.page(to.name ? to.name.toString() : to.path, {
      previousPath: from.path,
    });
  }

  trackEvent(eventName: string, metaObject: Record<string, any>) {
    this.analytics.track(eventName, metaObject);
  }

  setUserProperties({ user, org }) {
    if (user) {
      this.analytics.identify(user.userID, {
        name: `${user.firstName} ${user.lastName}`,
        phone: user.phone,
        email: user.email,
        createdAt: user.createdAt,
        activatedAt: user.activatedAt,
        status: user.status,
        role: user.role,
        team: user.team,
      });
    }
    if (org) {
      this.analytics.group(org.orgID, {
        name: org.name,
        website: org.website,
        isSupplier: org.isSupplier,
        isClient: org.isClient,
        paid: org.paid,
      });
    }
  }
}
