import LogRocket from 'logrocket';
import config from '@/config/widgets';
import type AnalyticsModule from './AnalyticsModule';

/* eslint class-methods-use-this: 0 */
export default class implements AnalyticsModule {
  constructor() {
    if (import.meta.env.MODE === 'prod') {
      LogRocket.init(config.logRocketID, {
        serverURL: 'https://lr-api.riskledger.com/i',
        // https://docs.logrocket.com/v1.0/reference#release
        release: config.buildSHA,
        dom: {
          inputSanitizer: true,
        },
        network: {
          requestSanitizer: (request) => {
            if (request.headers.Authorization) request.headers.Authorization = '*';
            // https://docs.logrocket.com/reference/network
            request.body = undefined;
            return request;
          },
          responseSanitizer(response) {
            response.body = undefined;
            return response;
          },
        },
      });
    }
  }

  trackEvent(eventName: string, properties: Record<string, any>) {
    // https://docs.logrocket.com/reference#track
    // track() accepts a single string argument
    LogRocket.track(eventName, properties);
  }

  logError(error: any, { route }: Record<string, any>) {
    // https://docs.logrocket.com/reference/capture-exception
    // Sends error to logrocket with optional tags and extras
    LogRocket.captureException(error, {
      extra: {
        route,
      },
    });
  }

  // https://docs.logrocket.com/reference#identify
  setUserProperties({ user, org }) {
    if (!user || !org) return;
    LogRocket.identify(user.userID, {
      // User
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      'Cognito ID': user.cognitoID,
      'Created At': user.createdAt,
      'Activated At': user.activatedAt,
      Status: user.status,
      Role: user.role,
      Team: user.team,

      // Organisation
      'Organisation Name': org.name,
      'Organisation ID': org.id,
      'Org Status': org.status,
      Paid: org.paid,
      Location: org.location,
      Supplier: org.isSupplier,
      Client: org.isClient,
      'Scoping Questions Complete': org.scopingComplete,
      'Initial Assessment Complete': org.initialAssessmentComplete,
      'Initial Assessment Completed At': org.initialAssessmentCompletedAt,
    });
  }
}
